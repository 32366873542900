import React from 'react';
import {
  useGridApiContext,
  gridColumnsTotalWidthSelector,
  gridColumnPositionsSelector,
  gridDensityRowHeightSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';
import { NUMBER_OF_ROWS } from '../../constants/data-grid';

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DataGridLoader = () => {
  const apiRef = useGridApiContext();
  const rowHeight = gridDensityRowHeightSelector(apiRef);
  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = React.useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions],
  );
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);
  const children = React.useMemo(() => {
    const array = [];
    for (let i = 0; i < NUMBER_OF_ROWS; i += 1) {
      columns.forEach((column) => {
        const width = Math.round(100);
        array.push(
          <SkeletonCell key={`col-${column.field}-${i}-${column.field}`} sx={{ justifyContent: column.align }}>
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </SkeletonCell>,
        );
      });
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [columns]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `${columns
          .map(({ computedWidth }) => `${computedWidth}px`)
          .join(' ')} 1fr`,
        gridAutoRows: `${rowHeight}px`,
      }}
    >
      {children}
    </div>
  );
};

export default DataGridLoader;
