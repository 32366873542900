import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { getTimeDiffText } from '../../helpers';

const ButtonDate = ({ func, days, disableWeekend, label, id }) => {
  const dayToJump = useMemo(() => {
    const requiredDate = moment().add(days, 'days');
    if (!disableWeekend) return requiredDate;
    const day = requiredDate.day();
    if (day === 0) {
      return requiredDate.add(1, 'days');
    }
    if (day === 6) {
      return requiredDate.subtract(1, 'day');
    }
    return requiredDate;
  }, [days, disableWeekend]);
  const onClick = useCallback(() => func(dayToJump), [dayToJump, func]);
  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      id={id}
      onClick={onClick}
      sx={{ mx: 'auto', width: '100%', px: 0 }}
    >
      {label || getTimeDiffText(dayToJump)}
    </Button>
  );
};

ButtonDate.propTypes = {
  func: PropTypes.func.isRequired,
  days: PropTypes.number.isRequired,
  disableWeekend: PropTypes.bool,
  label: PropTypes.string,
};

ButtonDate.defaultProps = {
  disableWeekend: true,
  label: null,
};

const DatePickerActionDates = ({
  onAllActions,
  closeDatePicker,
  debtorAveragePaymentDays,
  handleChange,
  disableWeekend,
  buttons,
}) => (
  <DialogActions>
    <Stack direction="column" spacing={1} sx={{ width: '100%', mt: -5 }}>
      <Grid
        container
        spacing={1}
        maxWidth={350}
        ml={-1}
      >
        {buttons.map(({ days, label, id }) => (
          <Grid item container xs={6} md={4} key={days}>
            <ButtonDate
              func={handleChange}
              disableWeekend={disableWeekend}
              days={days}
              label={label}
              id={id}
            />
          </Grid>
        ))}
      </Grid>
      {debtorAveragePaymentDays && (
      <ButtonDate
        func={handleChange}
        disableWeekend={disableWeekend}
        days={debtorAveragePaymentDays}
        label={`Plazo Pago Recomendado Deudor: ${debtorAveragePaymentDays} días`}
        id="button-debtor-recommended-payment-term"
      />
      )}
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={onAllActions}
          id="selectAllDates"
          size="small"
          sx={{ fontSize: '13px' }}
        >
          Seleccionar fecha para todas
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          id="button-save-date"
          sx={{ fontSize: '13px' }}
          onClick={closeDatePicker}
        >
          Guardar
        </Button>
      </Stack>
    </Stack>
  </DialogActions>
);

DatePickerActionDates.propTypes = {
  debtorAveragePaymentDays: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  closeDatePicker: PropTypes.func,
  onAllActions: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      days: PropTypes.number.isRequired,
      label: PropTypes.string,
    }).isRequired,
  ).isRequired,
  disableWeekend: PropTypes.bool,
};

DatePickerActionDates.defaultProps = {
  debtorAveragePaymentDays: null,
  onAllActions: null,
  disableWeekend: false,
  closeDatePicker: () => {},
};

export default DatePickerActionDates;
