import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { openSiiForm } from '../../helpers/sii-credentials-form-toggle';

import { useSelectedCompany } from '../../hooks';

const NoCredentialsErrorMessage = ({ ErrorMessage }) => {
  const selectedCompany = useSelectedCompany();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" paragraph>
        No tenemos documentos disponibles para mostrar
      </Typography>
      <Typography variant="body1" paragraph>
        <ErrorMessage />
        <Link onClick={() => { openSiiForm(selectedCompany); }}>credenciales del {t('National Tax Service acronym')}</Link>
        {' '} y refresca la página.
      </Typography>
    </>
  );
};

NoCredentialsErrorMessage.propTypes = {
  ErrorMessage: PropTypes.func.isRequired,
};

export default NoCredentialsErrorMessage;
