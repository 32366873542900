import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { formatMoney } from '../../helpers';

const TotalAmountCell = ({ moneyFields }) => {
  const totalByCurrency = moneyFields.reduce((acc, moneyField) => {
    const { amount, currency } = moneyField;
    const currencyCode = currency.code;

    acc[currencyCode] = acc[currencyCode]
      ? acc[currencyCode] + amount
      : amount;

    return acc;
  }, {});

  const currencyKeys = Object.keys(totalByCurrency);

  const tooltipContent = currencyKeys.map((currency) => ({
    currency,
    formattedAmount: formatMoney(
      { amount: totalByCurrency[currency], currency: { code: currency } },
      true,
    ),
  }));

  let renderValue;
  if (currencyKeys.length > 1) {
    renderValue = 'Multi monedas';
  } else if (currencyKeys.length === 1) {
    renderValue = formatMoney(
      { amount: totalByCurrency[currencyKeys[0]], currency: { code: currencyKeys[0] } },
      true,
    );
  } else {
    renderValue = 'No data';
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      {currencyKeys.length > 1 ? (
        <Tooltip
          title={(
            <Grid container direction="column">
              {tooltipContent.map(({ currency, formattedAmount }) => (
                <Grid item key={currency}>
                  <Typography variant="body2">
                    {formattedAmount}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        >
          <Typography variant="body1">{renderValue}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body1">{renderValue}</Typography>
      )}
    </Grid>
  );
};

TotalAmountCell.propTypes = {
  moneyFields: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default TotalAmountCell;
