import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { FilePropType } from '../../propTypes/FilePropType';

const AvatarCell = ({ user, align, ...extraProps }) => (
  <Tooltip
    arrow
    placement="top"
    title={user ? user?.completeName : 'Sin asignar'}
  >
    <Grid container alignItems="center" justifyContent={align}>
      <Avatar
        alt={user?.completeName || ''}
        src={user?.picture?.url || ''}
        {...extraProps}
      />
    </Grid>
  </Tooltip>
);

AvatarCell.propTypes = {
  user: PropTypes.shape({
    completeName: PropTypes.string,
    picture: FilePropType,
  }),
  align: PropTypes.string,
};

AvatarCell.defaultProps = {
  user: {
    firstName: 'Sin asignar',
    lastName: '',
    picture: null,
  },
  align: 'center',
};

export default AvatarCell;
