import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const DoubleInputDateRangePicker = (
  { title, dateFrom, dateTo, setDateFrom, setDateTo, datetime },
) => {
  const dateString = useMemo(() => (datetime ? 'YYYY-MM-DDTHH:mm:ss.SSSZ' : 'YYYY-MM-DD'), [datetime]);
  const from = useMemo(() => (dateFrom ? moment(dateFrom) : dateFrom), [dateFrom]);
  const to = useMemo(() => (dateTo ? moment(dateTo) : dateTo), [dateTo]);
  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {title}
      </Typography>
      <Stack direction="row" justifyContent="space-around">
        <DatePicker
          value={from}
          onChange={(value) => setDateFrom(value.format(dateString))}
          InputAdornmentProps={{ position: 'start' }}
          maxDate={to}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Desde:"
              variant="standard"
              sx={{ width: '40%', alignSelf: 'left' }}
            />
          )}
        />
        <DatePicker
          disablePast
          value={to}
          onChange={(value) => setDateTo(value.format(dateString))}
          InputAdornmentProps={{ position: 'start' }}
          minDate={from}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Hasta:"
              variant="standard"
              sx={{ width: '40%', alignSelf: 'left' }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

DoubleInputDateRangePicker.propTypes = {
  title: PropTypes.string,
  dateFrom: PropTypes.oneOf([PropTypes.string, null]),
  dateTo: PropTypes.oneOf([PropTypes.string, null]),
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  datetime: PropTypes.bool,
};
DoubleInputDateRangePicker.defaultProps = {
  title: 'Fechas:',
  dateFrom: null,
  dateTo: null,
  datetime: false,

};

export default DoubleInputDateRangePicker;
