import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseStatusSummaryStepperPremium from './BaseStatusSummaryStepperPremium';
import { useGetCountryFromUrl } from '../../hooks';
import { satStatusMaster, satSteps, SatCustomIconStepper } from './statusSteps/SatSteps';
import { siiStatusMaster, siiSteps, SiiCustomIconStepper } from './statusSteps/SiiSteps';

const StatusSummaryStepperPremium = ({ invoice }) => {
  const country = useGetCountryFromUrl();

  const CountryComponents = {
    Chile: {
      statusMaster: siiStatusMaster,
      steps: siiSteps,
      CustomIconStepper: SiiCustomIconStepper,
    },
    Mexico: {
      statusMaster: satStatusMaster,
      steps: satSteps,
      CustomIconStepper: SatCustomIconStepper,
    },
  };

  const { statusMaster, steps, CustomIconStepper } = CountryComponents[country?.name] || {};

  return (
    <BaseStatusSummaryStepperPremium
      invoice={invoice}
      siiStatusMaster={statusMaster}
      steps={steps}
      CustomIconStepper={CustomIconStepper}
    />
  );
};

StatusSummaryStepperPremium.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    receptionDate: PropTypes.objectOf(moment).isRequired,
    receiver: PropTypes.shape({
      rut: PropTypes.string,
    }),
    dteType: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};

export default StatusSummaryStepperPremium;
