import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import DateRange from '@mui/icons-material/DateRange';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useBooleanState } from '../../hooks';
import { formatDayMonthYearSlash } from '../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const DateInput = ({ startDate, endDate, PickerComponent, pickerProps }) => {
  const [open, toggleOpen] = useBooleanState();
  const classes = useStyles();
  const value = useMemo(() => {
    if (!startDate || !endDate) return '';
    return `${formatDayMonthYearSlash(startDate)} - ${formatDayMonthYearSlash(endDate)}`;
  }, [startDate, endDate]);
  return (
    <>
      <TextField
        label="Fechas"
        fullWidth
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <DateRange />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={toggleOpen}
        focused={open}
      />
      <Popover
        open={open}
        anchorReference="none"
        onClose={toggleOpen}
        classes={{
          root: classes.root,
        }}
      >
        <Box width="100%">
          <PickerComponent
            {...pickerProps}
            onClose={toggleOpen}
          />
          <Box ml="auto" width="fit-content" p={2}>
            <Button
              onClick={toggleOpen}
              size="small"
              variant="contained"
            >
              Elegir
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

DateInput.propTypes = {
  children: PropTypes.node.isRequired,
  startDate: PropTypes.objectOf(moment),
  endDate: PropTypes.objectOf(moment),
  PickerComponent: PropTypes.node.isRequired,
  pickerProps: PropTypes.shape(),
};

DateInput.defaultProps = {
  startDate: null,
  endDate: null,
  pickerProps: {},
};

export default DateInput;
