import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const OfferTransferConditionRatificationCell = ({ offer }) => {
  const isWithRestriction = offer.transferCondition === 'WITH_RATIFICATION';
  const message = useMemo(() => (isWithRestriction
    ? 'Contactaremos a tu cliente para validar esta factura'
    : 'Esta factura está lista para ser girada'), [isWithRestriction]);

  return (
    <Tooltip title={message}>
      <Typography color={isWithRestriction ? 'primary' : 'textPrimary'}>
        {isWithRestriction ? 'Con ratificación' : 'Sin ratificación'}
      </Typography>
    </Tooltip>
  );
};

OfferTransferConditionRatificationCell.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    transferCondition: PropTypes.string.isRequired,
  }).isRequired,
};
export default OfferTransferConditionRatificationCell;
