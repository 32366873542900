import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import _ from 'underscore';
import DataGridToolbar from './DataGridToolbar';

const FormDataGrid = ({
  rows,
  setRows,
  columns,
  boxProps,
  onCreate,
  onUpdate,
  onDelete,
  originalRows,
  isBackoffice,
  showToolbar,
  ...dataGridProps
}) => {
  const [rowModesModel, setRowModesModel] = useState({});

  const isNewRow = useCallback((row) => {
    const newRow = originalRows.find((originalRow) => originalRow.id === row.id);
    return (newRow === undefined);
  }, [originalRows]);
  const hasBeenUpdated = useCallback((row) => {
    const updatedRow = originalRows.find((originalRow) => originalRow.id === row.id);
    if (updatedRow === undefined) return false;
    return !_.isEqual(updatedRow, row);
  }, [originalRows]);

  const preventMuiDefault = useCallback(
    // eslint-disable-next-line no-param-reassign
    (params, event) => { event.defaultMuiPrevented = true; },
    [],
  );

  // eslint-disable-next-line no-unused-vars
  const handleEditClick = useCallback((id) => () => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.Edit } }));
  }, [setRowModesModel]);

  const handleSaveClick = useCallback((id) => () => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.View } }));
  }, [setRowModesModel]);

  const handleDeleteClick = useCallback((id) => () => {
    onDelete(id);
    setRows((prev) => prev.filter((row) => row.id !== id));
  }, [onDelete, setRows]);

  const handleCancelClick = useCallback((id) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  }, [rows, setRows]);

  const processRowUpdate = useCallback((newRow) => {
    if (hasBeenUpdated(newRow)) onUpdate(newRow);
    if (isNewRow(newRow)) onCreate(newRow);
    setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  }, [hasBeenUpdated, onUpdate, isNewRow, onCreate, setRows]);

  const dataGridColumns = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key="save-action"
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key="cancel-action"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (isBackoffice) {
          return [
            <GridActionsCellItem
              key="edit-action"
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              key="delete-action"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key="delete-action"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const ToolbarComponent = useMemo(() => {
    if (showToolbar) return DataGridToolbar;
    return () => false;
  }, [showToolbar]);
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': { color: 'text.secondary' },
        '& .textPrimary': { color: 'text.primary' },
      }}
      {...boxProps}
    >
      <DataGrid
        rows={rows}
        columns={dataGridColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={preventMuiDefault}
        onRowEditStop={preventMuiDefault}
        processRowUpdate={processRowUpdate}
        components={{ Toolbar: ToolbarComponent }}
        componentsProps={{ toolbar: { setRows, setRowModesModel, columns } }}
        experimentalFeatures={{ newEditingApi: true }}
        {...dataGridProps}
      />
    </Box>
  );
};

FormDataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setRows: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  boxProps: PropTypes.shape(),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  showToolbar: PropTypes.bool,
  originalRows: PropTypes.arrayOf(PropTypes.shape()),
  isBackoffice: PropTypes.bool,
};

FormDataGrid.defaultProps = {
  boxProps: {},
  onCreate: () => null,
  onUpdate: () => null,
  onDelete: () => null,
  originalRows: [],
  isBackoffice: true,
  showToolbar: true,
};
export default FormDataGrid;
