/* eslint-disable operator-linebreak */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getTimeDiffText, isEmpty } from '../../helpers';
import { diffDaysToColor, diffDaysToTooltipText, diffDaysToShowIcon } from '../../constants';
import { FINISHED_STATUS } from '../../constants/invoices';
import { AlertButton } from '../buttons';

const DateWithDaysDiffCell = ({ date, useColors, status }) => {
  const diffText = FINISHED_STATUS.includes(status) ? 'Recaudada' : getTimeDiffText(moment(date));
  const textColor =
    useColors && !FINISHED_STATUS.includes(status) ? diffDaysToColor(moment(date)) : '';
  const tooltipText = diffDaysToTooltipText(moment(date));
  const showIcon = FINISHED_STATUS.includes(status) ? false : diffDaysToShowIcon(moment(date));

  return (
    <Grid
      container
      alignItems="left"
      justifyContent="flex-start"
      overflow="hidden"
      direction="row"
      spacing={1}
    >
      {!isEmpty(date) && moment(date).isValid() ? (
        <>
          {showIcon && (
            <Grid item>
              <AlertButton
                id="alert-to-expire-cession-status"
                tooltipText={tooltipText}
                tooltipPlacement="left"
                color={textColor}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1" sx={{ color: textColor }}>{moment(date).format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption" sx={{ color: textColor }}>
              {diffText}
            </Typography>
          </Grid>
        </>
      ) : (
        <Grid item>
          <Typography variant="body1">Sin fecha</Typography>
        </Grid>
      )}
    </Grid>
  );
};

DateWithDaysDiffCell.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(moment)]),
  useColors: PropTypes.bool,
  status: PropTypes.string,
};

DateWithDaysDiffCell.defaultProps = {
  useColors: false,
  status: '',
  date: null,
};

export default DateWithDaysDiffCell;
