import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const DataGridRenderHeader = ({ colDef, field }) => (
  <Typography variant="body1" id={field}>
    {colDef.headerName}
  </Typography>
);

DataGridRenderHeader.propTypes = {
  colDef: PropTypes.shape({
    align: PropTypes.string,
    headerName: PropTypes.string,
  }).isRequired,
  field: PropTypes.string.isRequired,
};

export default DataGridRenderHeader;
