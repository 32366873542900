/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const DataGridCheckbox = React.forwardRef((props, ref) => useMemo(
  () => (

    <Tooltip title={props.disabled ? props.disabledCheckboxTooltip : ''}>
      <Box>
        <Checkbox
          ref={ref}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          id="dataGridCheckbox"
          {...props}
          sx={{
            p: { xs: 0, md: 'inherit' },
            '& .MuiSvgIcon-root': {
              fontSize: { xs: 15, md: 20 },
            },
          }}
        />
      </Box>
    </Tooltip>
  ),

  [props.checked, props.className, props.disabledCheckboxTooltip],
));

export default DataGridCheckbox;
