import React from 'react';
import Typography from '@mui/material/Typography';

const FirstLoadMessage = () => (
  <>
    <Typography variant="h4" paragraph>
      Estamos cargando tus facturas
    </Typography>
    <Typography variant="body1" paragraph>
      Porfavor espere unos minutos.
    </Typography>
  </>
);

export default FirstLoadMessage;
