import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ControlledDatePicker = ({ value, onChange, label, ...props }) => (
  <Stack direction="column">
    <Typography variant="formLabel">{label}:</Typography>
    <DatePicker
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, variant: 'form' }}
        />
      )}
      {...props}
    />
  </Stack>
);

ControlledDatePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ControlledDatePicker.defaultProps = {
  value: null,
};

export default ControlledDatePicker;
