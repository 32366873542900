import { useMemo } from 'react';
import { useIsMobile } from '../../hooks';

const FingoGridNode = ({ params, renderCell }) => {
  const isMobile = useIsMobile();
  return useMemo(() => renderCell(params), [
    params.row,
    params.value,
    isMobile,
  ]);
};

export default FingoGridNode;
