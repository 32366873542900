import React from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const FingoDatePicker = ({ gridMinWidth, gridProps, width, ...props }) => (
  <Grid gridMinWidth={gridMinWidth} {...gridProps} width={width}>
    <DatePicker
      className={
        {
          '& .MuiInputBase-root': {
            padding: '2px',
            '& .MuiButtonBase-root': {
              padding: '2px',
              paddingLeft: 10,
            },
            '& .MuiInputBase-input': {
              padding: 15,
              paddingLeft: 0,
            },
          },
        }
      }
      inputFormat="DD/MM/YYYY"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
        />
      )}
      {...props}
    />
  </Grid>
);

FingoDatePicker.propTypes = {
  gridMinWidth: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  gridProps: PropTypes.object,
  width: PropTypes.string,
};
FingoDatePicker.defaultProps = {
  gridMinWidth: '100%',
  gridProps: {},
  width: undefined,
};

export default FingoDatePicker;
