import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import makeStyles from '@mui/styles/makeStyles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, DateRange } from 'react-date-range';
import { es } from 'react-date-range/src/locale';
import PropTypes from 'prop-types';
import { fingoInputRanges, fingoStaticRanges } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  dayStartPreview: {
    border: 0,
    backgroundColor: theme.palette.primary.light,
  },
  dayToday: {
    '& .rdrDayNumber span:after': {
      background: theme.palette.primary.main,
    },
  },
}));

const FingoDateRangePicker = ({ classNames, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down('lg'),
    { defaultMatches: true },
  );
  if (isMobile) {
    return (
      <DateRange
        locale={es}
        color={theme.palette.primary.main}
        months={1}
        {...props}
        classNames={{
          dayStartPreview: classes.dayStartPreview,
          dayInPreview: classes.dayStartPreview,
          dayEndPreview: classes.dayStartPreview,
          dayToday: classes.dayToday,
          ...classNames,
        }}
      />
    );
  }
  return (
    <DateRangePicker
      locale={es}
      staticRanges={fingoStaticRanges}
      inputRanges={fingoInputRanges}
      color={theme.palette.primary.main}
      months={2}
      {...props}
      classNames={{
        dayStartPreview: classes.dayStartPreview,
        dayInPreview: classes.dayStartPreview,
        dayEndPreview: classes.dayStartPreview,
        dayToday: classes.dayToday,
        ...classNames,
      }}
    />

  );
};

FingoDateRangePicker.defaultProps = {
  classNames: {},
};

FingoDateRangePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classNames: PropTypes.object,
};

export default FingoDateRangePicker;
