import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { userHasRoutePermission } from '../../helpers/routes';
import AssignNewExecutiveCell from './AssignNewExecutiveCell';
import { useGetUser } from '../../hooks';
import { FilePropType } from '../../propTypes/FilePropType';

const PERMISSION_MAPPER = {
  ratification: 'debtors.change_debtor',
  collection: 'debtors.change_debtor',
  executive: 'customers.change_company',
};

const AvatarExecutiveAssignedCell = ({
  companyId,
  user,
  assignationType,
  ...props
}) => {
  const currentUser = useGetUser();
  const showAssign = useMemo(
    () => userHasRoutePermission(currentUser, PERMISSION_MAPPER[assignationType]),
    [],
  );

  const componentToShow = useMemo(() => {
    if (showAssign) {
      return (
        <AssignNewExecutiveCell
          companyId={companyId}
          user={user}
          assignationType={assignationType}
          {...props}
        />
      );
    }
    return (
      <Avatar
        alt={user?.firstName || ''}
        src={user?.picture?.url || ''}
        {...props}
      />
    );
  }, [user]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={user ? user.completeName : 'Sin asignar'}
    >
      <Grid container alignItems="center" justifyContent="center">
        {componentToShow}
      </Grid>
    </Tooltip>
  );
};

AvatarExecutiveAssignedCell.propTypes = {
  companyId: PropTypes.string,
  assignationType: PropTypes.oneOf(['ratification', 'collection', 'executive'])
    .isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    completeName: PropTypes.string.isRequired,
    picture: FilePropType,
  }),
};

AvatarExecutiveAssignedCell.defaultProps = {
  companyId: null,
  user: undefined,
};

export default AvatarExecutiveAssignedCell;
